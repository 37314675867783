@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  src: url(../public/FiraCode-Regular.ttf);
  font-weight: 400;
  font-family: 'FiraCode';
}

@font-face {
  src: url(../public/TTInterfaces-Regular.otf);
  font-weight: 400;
  font-family: 'TTInterfaces';
}

.text-2xs {
  font-size: 0.675rem;
  line-height: 1rem;
}

.w-1\/8 {
  width: 12.5%;
}

.w-1\/7 {
  width: 14.2857143%;
}

@media (min-width: 768px) {
  .md\:w-1\/7 {
    width: 14.2857143%;
  }
}

@font-face {
  src: url(../public/TTInterfaces-Medium.otf);
  font-weight: 500;
  font-family: 'TTInterfaces';
}

@font-face {
  src: url(../public/TTInterfaces-Bold.otf);
  font-weight: 700;
  font-family: 'TTInterfaces';
}

@font-face {
  src: url(../public/fonts/AvenirLTStd-Black.otf);
  font-weight: 800;
  font-family: 'Avenir';
}

@font-face {
  src: url(../public/fonts/AvenirLTStd-Roman.otf);
  font-weight: 500;
  font-family: 'Avenir';
}

@font-face {
  src: url(../public/fonts/AvenirLTStd-Book.otf);
  font-weight: 300;
  font-family: 'Avenir';
}

@font-face {
  src: url(../public/fonts/AvenirLTStd-Black.otf);
  font-weight: 800;
  font-family: 'Avenir';
}

@font-face {
  src: url(../public/fonts/Nexa-ExtraLight.ttf);
  font-weight: 300;
  font-family: 'Nexa';
}

@font-face {
  src: url(../public/fonts/Nexa-Heavy.ttf);
  font-weight: 800;
  font-family: 'Nexa';
}

#my-tooltip {
  font-size: 12px;
}

#my-volume-tooltip {
  font-size: 12px;
  font-weight: 100;
}

#maxapr {
  font-size: 12px;
  font-weight: 100;
}

h1 {
  @apply text-6xl sm:text-7xl lg:text-8xl;
}

h2 {
  @apply text-4xl sm:text-5xl lg:text-6xl;
}

h3 {
  @apply text-3xl sm:text-4xl lg:text-5xl;
}

p {
  @apply text-base font-thin;
}

input {
  @apply text-sm font-thin;
  color: white;
}

textarea {
  @apply text-sm font-thin;
  color: white;
}

th {
  @apply text-left;
}

td {
  @apply text-left;
}

.button-primary {
  @apply py-1.5 px-4 pt-2 hover:bg-self/80 bg-self text-white rounded-md;
}

.button-secondary {
  @apply py-1.5 px-4 border hover:bg-black/5 border-black/5 text-self rounded-md;
}

.pricing-gradient {
  background: radial-gradient(circle, hsla(244, 42%, 31%, 1) 0%, hsla(244, 69%, 14%, 1) 100%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

body {
  font-family: Inter;
}
